<template>
  <div :class="$style.attributes">
    <div :class="$style.responsive">
      <table :class="$style.tabel">
        <thead :class="$style.header">
          <tr :class="$style.row">
            <th :class="[$style.draggable, $style.nograb]"></th>
            <th :class="$style.section">№ п/п</th>
            <th :class="$style.name">Название атрибута</th>
            <th :class="$style.name">Название из МС</th>
            <th :class="$style.name">URL</th>
            <th :class="$style.section">В фильтрах</th>
          </tr>
        </thead>
        <Container
          tag="tbody"
          :class="$style.body"
          @drop="onDrop"
          @drag-start="drag = false"
          @drag-end="drag = true"
        >
          <Draggable
            tag="tr"
            :class="$style.row"
            v-for="item in attributes"
            :key="item.id"
          >
            <td :class="$style.draggable">
              <Icon name="draggable" :class="$style.icon" />
            </td>
            <td :class="$style.section">
              {{ item.orderField }}
            </td>
            <td :class="$style.name">
              {{ item.attribute !== null ? item.attribute.name : '' }}
            </td>
            <td :class="$style.name">
              {{ item.moyskladAttributeName }}
            </td>
            <td :class="$style.name">
              {{ item.attribute !== null ? item.attribute.slug : '' }}
            </td>
            <td :class="$style.section" v-if="item.attribute !== null">
              <input
                type="checkbox"
                onclick="return false"
                :checked="item.attribute.isDisplayed"
                :class="$style.checkbox"
              />
            </td>
          </Draggable>
        </Container>
      </table>
    </div>
  </div>
</template>

<script>
import delivery from '@/delivery'
import Icon from '@/components/atoms/Icon'

export default {
  components: { Icon },
  data() {
    return {
      attributes: []
    }
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      const loading = this.$loading({
        lock: true
      })
      const { value, error } =
        await delivery.ProductsCore.RankedAttributesActions.getList()
      if (error) return
      this.attributes = value

      loading.close()
    },
    async save(list) {
      const loading = this.$loading({
        lock: true
      })

      var orderField = 0
      var updateList = []

      list.forEach((item) => {
        orderField++
        if (item.orderField != orderField) {
          item.orderField = orderField
          updateList.push(item)
        }
      })

      const res = await delivery.ProductsCore.RankedAttributesActions.update(
        updateList
      )
      loading.close()
      if (res.error) return
    },
    applyDrag(arr, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult
      if (removedIndex === null && addedIndex === null) return arr

      const result = [...arr]
      let itemToAdd = payload

      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0]
      }

      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd)
      }

      return result
    },
    onDrop(dropResult) {
      document.body.classList.remove('smooth-dnd-no-user-select')
      document.body.classList.remove('smooth-dnd-disable-touch-action')
      this.attributes = this.applyDrag(this.attributes, dropResult)

      this.save(this.attributes)
    }
  }
}
</script>

<style lang="scss" module>
.attributes {
  overflow: hidden;

  h2 {
    padding: 1rem 0;
  }

  .responsive {
    border-radius: 0.25rem;
    @include tabelShadow;
    width: 100%;
    overflow-x: auto;
    .tabel {
      width: 100%;
      table-layout: auto;
      .header {
        th {
          color: $tabel-th-color;
          font-weight: 700;
          text-align: left;
        }
      }
      .row {
        display: block;
        padding: 0.5rem;
        border-bottom: 1px solid $tabel-border;
        &:nth-child(odd) {
          background-color: $bgc-tabel-row;
        }
        .draggable {
          min-width: 5rem;
          cursor: move;
          &.nograb {
            cursor: default;
          }
          .icon {
            width: 1rem;
            height: 1rem;
          }
        }
        .name {
          min-width: 25rem;
        }
        .section {
          min-width: 12.75rem;
        }
      }
    }
  }
}
</style>
